import { ContainerListPage } from '../../../../../../components/List/styles';
import { returnCheckType } from '../../../../../../utils/returnCheckType';
import { QuestionnaireRow } from '../../page';

type TableProps = {
  rows: QuestionnaireRow[];
  setSelectedRow: (row: QuestionnaireRow) => void;
};

export const Table = ({ rows, setSelectedRow }: TableProps) => {
  return (
    <ContainerListPage>
      <table>
        <thead>
          <tr>
            <th className="first-table-th">Ativo</th>
            <th className="list-th">Descrição</th>
            <th className="last-table-th">Observação</th>
          </tr>
        </thead>
        <tbody>
          {rows.map((row, index) => (
            <tr
              key={index}
              onClick={() => setSelectedRow(row)}
              style={{
                cursor: 'pointer',
                fontWeight: 'bold',
              }}
            >
              <td className="list-td-small">
                <img className="icon-check" src={returnCheckType(row.ativo)} />
              </td>
              <td className="list-td-small">{row.descricao}</td>
              <td className="list-td-large">{row.observacao}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </ContainerListPage>
  );
};
