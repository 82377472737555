import { ContainerListPage } from '../../../../../../components/List/styles';
import { ModalInfoTemplete } from '../../../../../../components/Modal/ModalInfoTemplete';
import {
  ContainerItemModal,
  RowItens,
} from '../../../../../../components/Modal/ModalInfoTemplete/styles';
import { PageHeader } from '../../../../../../components/PageHeader';
import {
  ContainerPage,
  PageContent,
} from '../../../../../../styles/global.styles';
import { returnCheckType } from '../../../../../../utils/returnCheckType';
import { QuestionnaireRow } from '../../page';

type ContentWrapperProps = {
  loading: boolean;
  row: QuestionnaireRow | null;
  openModal: boolean;
  handleCloseModal: () => void;
  children: React.ReactNode;
};

type QuestionnaireModalProps = {
  row: QuestionnaireRow;
  openModal: boolean;
  handleCloseModal: () => void;
};

const QuestionnaireModal = ({
  row,
  openModal,
  handleCloseModal,
}: QuestionnaireModalProps) => {
  return (
    <ModalInfoTemplete
      option={'pqfl_questionarios'}
      titleModal={'Questionário PQFL'}
      closeModal={handleCloseModal}
      isOpen={openModal}
    >
      <RowItens
        style={{
          marginBottom: '10px',
        }}
      >
        <ContainerItemModal>
          <label>Código</label>
          <div>
            <span>{row.id ? row.id : 'Não informado'}</span>
          </div>
        </ContainerItemModal>
        <ContainerItemModal>
          <label>Descrição</label>
          <div>
            <span>{row.descricao ? row.descricao : 'Não informado'}</span>
          </div>
        </ContainerItemModal>
      </RowItens>
      <RowItens
        style={{
          marginBottom: '20px',
        }}
      >
        <ContainerItemModal>
          <label>Observação</label>
          <div>
            <span>{row.observacao ? row.observacao : 'Não informado'}</span>
          </div>
        </ContainerItemModal>
        <ContainerItemModal>
          <label>Ativo</label>
          <img src={returnCheckType(row.ativo)} />
        </ContainerItemModal>
      </RowItens>
      <RowItens
        style={{
          marginBottom: '10px',
        }}
      >
        <h2>Perguntas:</h2>
      </RowItens>
      <RowItens>
        <ContainerListPage
          style={{
            width: '100%',
            overflow: 'auto',
          }}
        >
          <table>
            <thead>
              <tr>
                <th className="first-table-th">Produto</th>
                <th className="list-th">Descrição</th>
                <th className="list-th">Ordem</th>
              </tr>
            </thead>
            <tbody>
              {row.itens
                .sort((a, b) => a.ordem - b.ordem)
                .map((item, index) => (
                  <tr key={index}>
                    <td className="list-td-small">{item.id}</td>
                    <td className="list-td-large">{item.pergunta.texto}</td>
                    <td className="list-td-small">{item.ordem}</td>
                  </tr>
                ))}
            </tbody>
          </table>
        </ContainerListPage>
      </RowItens>
    </ModalInfoTemplete>
  );
};

export const ContentWrapper = ({
  loading,
  row,
  openModal,
  handleCloseModal,
  children,
}: ContentWrapperProps) => {
  return (
    <>
      <PageHeader
        pageIcon={{ option: 'pqfl_questionarios' }}
        pageTitle="PQFL / Questionários"
      />
      <ContainerPage>
        <PageContent
          style={{
            justifyContent: `${loading === true ? 'center' : 'space-between'}`,
          }}
        >
          {children}
        </PageContent>
        {row && (
          <QuestionnaireModal
            row={row}
            openModal={openModal}
            handleCloseModal={handleCloseModal}
          />
        )}
      </ContainerPage>
    </>
  );
};
