/* eslint-disable consistent-return */
import { FC, useEffect, useState } from 'react';
import Loading from 'react-loading';
import { useNavigate, useLocation } from 'react-router-dom';

import { AppVersion } from '../../../../../types/AppVersion';
import LogoMeta from '../../../assets/copy2.png';
import Logo from '../../../assets/logo2.png';
import Marca from '../../../assets/marca2.png';
import { ReactComponent as SelectUnity } from '../../../assets/newicons/FABRICA.svg';
import { ReactComponent as Unlock } from '../../../assets/Unlock.svg';
import { ReactComponent as UserData } from '../../../assets/UserData.svg';
import { FormAuthInput } from '../../../components/Inputs/FormAuthInput';
import { FormAuthSelect } from '../../../components/Selects/FormAuthSelect';
import { useSigninContext } from '../../../context/AuthProviderPages/Signin';
import { returnUrlWithHttp } from '../../../utils/returnUrlWithHttp';
import { ContainerSignin } from './styles';

const Signin: FC<AppVersion> = ({ version }) => {
  const [DFLOGINUSUARIO, SETDFLOGINUSUARIO] = useState<string>('');
  const [DFSENHAUSUARIO, SETDFSENHAUSUARIO] = useState<string>('');
  const [localPath, setLocalPath] = useState('');
  const data = new Date();
  const navigate = useNavigate();
  const location = useLocation();

  const {
    handleLoginUser,
    loadDataBase,
    setPath,
    unity,
    setUnity,
    unityOptions,
    loadingLogin,
  } = useSigninContext();

  const handleNavigateToCollect = () => {
    navigate(`/coleta${version}`);
  };

  const executeHandleLoginUser = (event: React.FormEvent<HTMLFormElement>) => {
    handleLoginUser({
      event,
      DFLOGINUSUARIO,
      DFSENHAUSUARIO,
      path: localPath,
      handleNavigateToCollect,
    });
  };

  useEffect(() => {
    const url = returnUrlWithHttp(window.location.href);
    // const url = 'saboroso.datamilk.com.br';
    console.log(url);

    if (url) {
      setPath(url);
      setLocalPath(url);
      loadDataBase(url);
    }
  }, [location]);

  return (
    <ContainerSignin>
      <div className="backgorund-signin-image">
        <img src={Marca} className="marc" />
        <form autoComplete="off" onSubmit={e => executeHandleLoginUser(e)}>
          <div className="form-logo">
            <img src={Logo} className="form-ilustration" />
          </div>
          <div className="form-content">
            <input style={{ display: 'none' }} name="login" type="text" />
            <FormAuthInput
              {...{
                icon: <UserData />,
                setValue: SETDFLOGINUSUARIO,
                value: DFLOGINUSUARIO,
                placeholder: 'Login',
                autoComplete: 'off',
              }}
            />
            <FormAuthInput
              {...{
                icon: <Unlock />,
                type: 'password',
                setValue: SETDFSENHAUSUARIO,
                value: DFSENHAUSUARIO,
                placeholder: 'Senha',
                autoComplete: 'off',
              }}
            />
            <FormAuthSelect
              {...{
                setValue: setUnity,
                value: unity,
                icon: <SelectUnity />,
                unities: unityOptions,
              }}
            />
            <button>
              {loadingLogin ? (
                <Loading color="white" type="spin" width={30} height={30} />
              ) : (
                'Entrar'
              )}
            </button>
          </div>
        </form>
        <div className="copyright">
          <div style={{ width: 120 }} />
          <span>
            Meta Tecnologia em Software do Brasil LTDA - Copyright ©{' '}
            {data.getFullYear()} Todos os direitos reservados.
          </span>
          <a href="https://www.webmeta.com.br" target="_blank">
            <img src={LogoMeta} />
          </a>
        </div>
      </div>
    </ContainerSignin>
  );
};

export { Signin };
