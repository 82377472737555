import { DateTime } from 'luxon';

import { ListButton } from '../../../../../../components/Buttons/ListButton';
import { ContainerListPage } from '../../../../../../components/List/styles';
import { PQFLVisitRow } from '../../page';

type TableProps = {
  rows: PQFLVisitRow[];
  setSelectedRow: (row: PQFLVisitRow) => void;
};

export const formatISODate = (date: string | null) => {
  if (!date) return '-';
  return DateTime.fromISO(date).toFormat('dd/MM/yyyy HH:mm');
};

export const Table = ({ rows, setSelectedRow }: TableProps) => {
  return (
    <ContainerListPage>
      <table>
        <thead>
          <tr>
            <th className="first-table-th">Código</th>
            <th className="list-td-large">Propriedade</th>
            <th className="list-td-large">Produtor</th>
            <th className="list-td-medium">Responsável</th>
            <th className="list-th">Status</th>
            <th className="list-td-medium">Início</th>
            <th className="list-td-medium">Fim</th>
            <th className="list-td-medium">Opções</th>
          </tr>
        </thead>
        <tbody>
          {rows.map((row, index) => (
            <tr
              key={index}
              onClick={() => setSelectedRow(row)}
              style={{
                cursor: 'pointer',
                fontWeight: 'bold',
              }}
            >
              <td>{row.id}</td>
              <td>{row.propriedade.nome_propriedade}</td>
              <td>{row.propriedade.nome_produtor}</td>
              <td>{row.funcionario.nome}</td>
              <td>{row.status}</td>
              <td>{formatISODate(row.data_inicio)}</td>
              <td>{formatISODate(row.data_final)}</td>
              <td className="list-td-smal">
                <div>
                  <ListButton
                    action={async () => {
                      // eslint-disable-next-line global-require
                      const html2pdf = await require('html2pdf.js');
                      const datePrefix = DateTime.fromISO(
                        row.data_inicio,
                      ).toFormat('dd-MM-yyyy_HH-mm');

                      setTimeout(() => {
                        const element =
                          document.querySelector('#visit-document');
                        html2pdf(element, {
                          margin: [7, 2],
                          filename: `${datePrefix}-visita-meta-pqfl.pdf`,
                        });
                      }, 100);
                    }}
                    icon={'print'}
                  />
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </ContainerListPage>
  );
};
