import axios from 'axios';

export const META_COLLECT_API = axios.create({
  // homolog
  baseURL: 'https://homolog.api.datamilk.com.br/v1',
  // production
  // baseURL: 'https://api.datamilk.com.br/v1',
  // localhost
  // baseURL: 'http://localhost:3335/v1',
});
