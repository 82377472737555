import { DischargeSilo } from '../../../types/DischargeSilo';
import { Silo } from '../../../types/Silo';
import { DischargeSiloItem } from '../../DischargeSiloItem';
import { ListDischargeSiloContainer } from './styles';

interface IListDischargeSilo {
  data: DischargeSilo[];
  handleRemoveDischargeSilo: (silo: Silo) => void;
  handleOpenEditDischargeSilo: (silo: DischargeSilo) => void;
}

const ListDischargeSilo = ({
  data,
  handleRemoveDischargeSilo,
  handleOpenEditDischargeSilo,
}: IListDischargeSilo) => {
  return (
    <ListDischargeSiloContainer>
      {data.map((item, index) => {
        return (
          <DischargeSiloItem
            {...{
              item,
              handleRemoveDischargeSilo,
              handleOpenEditDischargeSilo,
            }}
            key={index}
          />
        );
      })}
    </ListDischargeSiloContainer>
  );
};

export { ListDischargeSilo };
