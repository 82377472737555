import { DischargeSilo } from '../../../types/DischargeSilo';
import { IMouth, IMouthsWithStorageValueApp } from '../../../types/MouthTank';

const calculateRemainingValueForMouth = (
  dischargeSilo: DischargeSilo[],
  mouthsWithStorageValueApp: IMouthsWithStorageValueApp[],
  DFBOCA: number,
) => {
  let similarStorage: IMouth[] = [];
  let totalStorageValue = 0;
  const mouthStorageByApp = mouthsWithStorageValueApp.find(
    i => i.DFBOCA === DFBOCA,
  );
  dischargeSilo.forEach(silo => {
    const discharMouths = silo.selectedSiloMouths || [];
    discharMouths.forEach(mouth => {
      if (mouth.DFBOCA === DFBOCA) {
        similarStorage.push(mouth);
      }
    });
  });
  similarStorage.forEach(mouth => {
    totalStorageValue += parseFloat(mouth.DFVOLUME || '0');
  });
  if (totalStorageValue === 0 && mouthStorageByApp) {
    return parseFloat(mouthStorageByApp.DFVOLUMETOTAL || '0');
  }
  if (totalStorageValue > 0 && mouthStorageByApp) {
    return (
      parseFloat(mouthStorageByApp.DFVOLUMETOTAL || '0') - totalStorageValue
    );
  }
  return '0';
};

export { calculateRemainingValueForMouth };
