import { ContainerListPage } from '../../../../../../components/List/styles';
import { returnCheckType } from '../../../../../../utils/returnCheckType';
import { QuestionRow } from '../../page';

type TableProps = {
  rows: QuestionRow[];
  setSelectedRow: (row: QuestionRow) => void;
};

export const Table = ({ rows, setSelectedRow }: TableProps) => {
  return (
    <ContainerListPage>
      <table>
        <thead>
          <tr>
            <th className="first-table-th">Ativo</th>
            <th className="list-th">Código</th>
            <th className="last-table-th">Pergunta</th>
          </tr>
        </thead>
        <tbody>
          {rows.map((row, index) => (
            <tr
              key={index}
              onClick={() => setSelectedRow(row)}
              style={{
                cursor: 'pointer',
                fontWeight: 'bold',
              }}
            >
              <td className="list-td-small">
                <img className="icon-check" src={returnCheckType(row.ativo)} />
              </td>
              <td
                className="list-td-small"
                style={{
                  fontWeight: row.grau2 === '000' ? 'bold' : 'normal',
                }}
              >
                {row.grau1}.{row.grau2}
              </td>
              <td
                className="list-td-large"
                style={{
                  fontWeight: row.grau2 === '000' ? 'bold' : 'normal',
                }}
              >
                {row.texto}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </ContainerListPage>
  );
};
