/* eslint-disable @typescript-eslint/naming-convention */
import { DateTime } from 'luxon';
import React from 'react';

import { PQFLAnswer, PQFLAnswerItem } from '../../page';
import styles from './pdf.module.css';

interface Property {
  name: string;
  municipalityName: string;
}

interface Company {
  legalName: string;
}

interface PDFProps {
  base64Data?: string;
  username?: string;
  company: Company;
  property: Property;
  endDate: string | null;
  answers: PQFLAnswer[];
  technicalRecommendation?: string | null;
  signature?: string;
  technicianSignature?: string;
}

const removeZeroToTheLeft = (value: string) => {
  return value.replace(/^0+/, '');
};

const getAnswerValue = (item: PQFLAnswerItem) => {
  if (item.tipo_pergunta === 1) {
    return item.valor === 'S' ? 'Sim' : 'Não';
  }

  if (item.tipo_pergunta === 3) {
    return DateTime.fromISO(item.valor).toFormat('dd/MM/yyyy');
  }

  if (item.tipo_pergunta === 4) {
    return DateTime.fromISO(item.valor).toFormat('HH:mm');
  }

  return item.valor;
};

const PDF: React.FC<PDFProps> = ({
  base64Data,
  username,
  company,
  property,
  endDate,
  answers,
  technicalRecommendation,
  signature,
  technicianSignature,
}) => {
  return (
    <div id="visit-document" className={styles.container}>
      <div className={styles.header}>
        <div className={styles.logo}>
          {base64Data && (
            <img
              width="100%"
              height="100%"
              // fit content  config
              content="fit"
              src={`data:image/png;base64,${base64Data}`}
              alt="Logo"
            />
          )}
        </div>
        <div
          className={styles.info}
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
            padding: '10px 10px 10px 50px',
          }}
        >
          <h1 className={styles.title}>CHECK LIST DE QUALIDADE</h1>
          <h1 className={styles.subtitle}>{company.legalName}</h1>
        </div>
        <div
          style={{
            width: '130px',
            display: 'flex',
            flexDirection: 'column',
            letterSpacing: '1.25px',
            fontSize: '0.75rem',
          }}
        >
          <p>Data....: {DateTime.local().toFormat('dd/MM/yyyy')}</p>
          <p>Hora....: {DateTime.local().toFormat('HH:mm')}</p>
          <p>Usuário: {username}</p>
        </div>
      </div>

      <div className={styles.formGroup}>
        <div className={styles.grid}>
          <div>
            <label>Nome do Produtor:</label>
            <input type="text" value={property.name} disabled />
          </div>
          <div>
            <label>Data da visita:</label>
            <input
              type="text"
              value={
                endDate
                  ? DateTime.fromISO(endDate).toFormat('dd/MM/yyyy HH:mm')
                  : '-'
              }
              disabled
            />
          </div>
          <div>
            <label>Propriedade:</label>
            <input type="text" value={property.name} disabled />
          </div>
          <div>
            <label>Município:</label>
            <input type="text" value={property.municipalityName} disabled />
          </div>
        </div>
      </div>

      <table className={styles.table}>
        <thead>
          <tr>
            <th className={styles.th}>CBT (x 1.000 UFC/mL)</th>
            <th className={styles.th}>CCS (x 1.000 CCS/mL)</th>
            <th className={styles.th}>Gordura (%)</th>
            <th className={styles.th}>Proteína (%)</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className={styles.td}>-</td>
            <td className={styles.td}>-</td>
            <td className={styles.td}>-</td>
            <td className={styles.td}>-</td>
          </tr>
        </tbody>
      </table>

      {answers.map((answer, index) => (
        <div key={index}>
          <h2 className={styles.sectionTitle}>
            {index + 1}. {answer.questionario.descricao}
          </h2>
          <table className={styles.table}>
            <thead>
              <tr>
                <th className={styles.th}>Pergunta</th>
                <th className={styles.th} style={{ width: '200px' }}>
                  Resposta
                </th>
              </tr>
            </thead>
            <tbody>
              {answer.itens.map((item, idx) => (
                <tr key={idx}>
                  <td className={styles.td}>
                    {removeZeroToTheLeft(item.grau1_pergunta)}.
                    {removeZeroToTheLeft(item.grau2_pergunta)}{' '}
                    {item.texto_pergunta}
                  </td>
                  <td className={styles.answerColumn}>
                    {getAnswerValue(item)}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ))}

      {technicalRecommendation && technicalRecommendation.length > 0 && (
        <div>
          <h2 className={styles.sectionTitle}>Recomendações:</h2>
          <p
            dangerouslySetInnerHTML={{
              __html: technicalRecommendation.replace(/\n/g, '<br>'),
            }}
          />
        </div>
      )}

      <div
        style={{
          display: 'flex',
          justifyContent: 'space-around',
          margin: '40px 0 40px 0',
        }}
      >
        <div style={{ textAlign: 'center' }}>
          <div className={styles.signature}>
            {signature && (
              <img
                width="100%"
                height="100%"
                src={`data:image/png;base64,${signature}`}
                alt="Signature"
              />
            )}
          </div>
          <span>______________________________________</span>
          <p style={{ marginTop: '10px' }}>Produtor / Responsável</p>
        </div>
        <div style={{ textAlign: 'center' }}>
          <div className={styles.signature}>
            {technicianSignature && (
              <img
                width="100%"
                height="100%"
                src={`data:image/png;base64,${technicianSignature}`}
                alt="Technician Signature"
              />
            )}
          </div>
          <span>______________________________________</span>
          <p style={{ marginTop: '10px' }}>Técnico</p>
        </div>
      </div>
    </div>
  );
};

export default PDF;
