import { ModalInfoTemplete } from '../../../../../../components/Modal/ModalInfoTemplete';
import {
  ContainerItemModal,
  RowItens,
} from '../../../../../../components/Modal/ModalInfoTemplete/styles';
import { PageHeader } from '../../../../../../components/PageHeader';
import {
  ContainerPage,
  PageContent,
} from '../../../../../../styles/global.styles';
import { returnCheckType } from '../../../../../../utils/returnCheckType';
import { QuestionRow } from '../../page';

type ContentWrapperProps = {
  loading: boolean;
  question: QuestionRow | null;
  openModal: boolean;
  handleCloseModal: () => void;
  children: React.ReactNode;
};

type QuestionModalProps = {
  question: QuestionRow;
  openModal: boolean;
  handleCloseModal: () => void;
};

const QuestionModal = ({
  question,
  openModal,
  handleCloseModal,
}: QuestionModalProps) => {
  return (
    <ModalInfoTemplete
      option={'pqfl_perguntas'}
      titleModal={'Pergunta PQFL'}
      closeModal={handleCloseModal}
      isOpen={openModal}
    >
      <RowItens>
        <ContainerItemModal>
          <label>Código</label>
          <div>
            <span>
              {question.grau1 && question.grau2
                ? `${question.grau1}.${question.grau2}`
                : 'Não informado'}
            </span>
          </div>
        </ContainerItemModal>
        <ContainerItemModal>
          <label>Pergunta</label>
          <div>
            <span>{question.texto}</span>
          </div>
        </ContainerItemModal>
      </RowItens>
      <RowItens>
        <ContainerItemModal>
          <label>Ativo</label>
          <img src={returnCheckType(question.ativo)} />
        </ContainerItemModal>
      </RowItens>
    </ModalInfoTemplete>
  );
};

export const ContentWrapper = ({
  loading,
  question,
  openModal,
  handleCloseModal,
  children,
}: ContentWrapperProps) => {
  return (
    <>
      <PageHeader
        pageIcon={{ option: 'pqfl_perguntas' }}
        pageTitle="PQFL / Perguntas"
      />
      <ContainerPage>
        <PageContent
          style={{
            justifyContent: `${loading === true ? 'center' : 'space-between'}`,
          }}
        >
          {children}
        </PageContent>
        {question && (
          <QuestionModal
            question={question}
            openModal={openModal}
            handleCloseModal={handleCloseModal}
          />
        )}
      </ContainerPage>
    </>
  );
};
