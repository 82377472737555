import { useEffect, useState } from 'react';

import { META_COLLECT_API } from '../../../../api';
import { DontExistsItem } from '../../../../components/DontExistsItem';
import { DontExistsItemFlex } from '../../../../components/DontExistsItem/styles';
import { LoadingData } from '../../../../components/LoadingData';
import { useSigninContext } from '../../../../context/AuthProviderPages/Signin';
import { usePageContext } from '../../../../context/usePage';
import { Paginator } from '../components/paginator';
import { ContentWrapper } from './components/content-wrapper';
import { Table } from './components/table';

export type QuestionRow = {
  id: number;
  ativo: string;
  grau1: string;
  grau2: string;
  texto: string;
  observacao: string | null;
  tipo: number;
  obrigatorio: string;
};

export const PQFLQuestionsScreen = () => {
  const { setSelectedPage } = usePageContext();
  const { path } = useSigninContext();

  const [loading, setLoading] = useState<boolean>(false);
  const [rows, setRows] = useState<QuestionRow[]>([]);
  const [selectedRow, setSelectedRow] = useState<QuestionRow | null>(null);
  const [hasMore, setHasMore] = useState<boolean>(false);
  const [count, setCount] = useState<number>(0);
  const [limit, setLimit] = useState<number>(15);
  const [page, setPage] = useState<number>(1);

  const fetchQuestions = async () => {
    try {
      const { data: response } = await META_COLLECT_API.get(
        `/pqfl/questions?limit=${limit}&page=${page}`,
        {
          headers: { path },
        },
      );
      setHasMore(response.hasMore);
      setCount(response.count);
      setRows(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    setSelectedPage({ option: 'pqfl_perguntas' });
  }, []);

  useEffect(() => {
    setLoading(true);
    fetchQuestions().finally(() => setLoading(false));
  }, [limit, page]);

  if (loading) {
    return (
      <ContentWrapper
        loading={loading}
        question={selectedRow}
        openModal={!!selectedRow}
        handleCloseModal={() => setSelectedRow(null)}
      >
        <LoadingData title="Carregando..." />
      </ContentWrapper>
    );
  }

  return (
    <ContentWrapper
      loading={loading}
      question={selectedRow}
      openModal={!!selectedRow}
      handleCloseModal={() => setSelectedRow(null)}
    >
      {rows.length ? (
        <>
          <Table rows={rows} setSelectedRow={setSelectedRow} />
          <Paginator
            size={rows.length}
            limit={limit}
            setLimit={setLimit}
            page={page}
            setPage={setPage}
            hasMore={hasMore}
            count={count}
          />
        </>
      ) : (
        <DontExistsItemFlex>
          <DontExistsItem title="Nenhuma pergunta encontrada" />
        </DontExistsItemFlex>
      )}
    </ContentWrapper>
  );
};
