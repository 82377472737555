import { Dispatch, SetStateAction, useEffect } from 'react';

import { useUnityContext } from '../../../context/ProviderPage/Unidade';
import { Unity } from '../../../types/Unity';
import { PanelConfigurationOption } from './PanelConfigurationOption';
import { ListPanelConfigurationContainer } from './styles';

interface IListPanelConfiguration {
  cip: boolean;
  setCip: Dispatch<SetStateAction<boolean>>;
  motorist: boolean;
  setMotorist: Dispatch<SetStateAction<boolean>>;
  unity: Unity;
  setUnity: Dispatch<SetStateAction<Unity>>;
  moutBoundSilo: boolean;
  setMoutBoundSilo: Dispatch<SetStateAction<boolean>>;
}

const ListPanelConfiguration = ({
  cip,
  motorist,
  setCip,
  setMotorist,
  moutBoundSilo,
  setMoutBoundSilo,
}: IListPanelConfiguration) => {
  const { loadUnitiesAction } = useUnityContext();

  useEffect(() => {
    loadUnitiesAction();
  }, []);

  return (
    <ListPanelConfigurationContainer>
      <div className="panel-configuration-title">
        <h1>Plataforma</h1>
      </div>
      <PanelConfigurationOption
        {...{
          label: 'Informar dados realacionados ao CIP? ',
          value: cip,
          setValue: setCip,
        }}
      />
      <PanelConfigurationOption
        {...{
          label: 'Informar dados realacionados ao Motorista?',
          value: motorist,
          setValue: setMotorist,
        }}
      />
      <PanelConfigurationOption
        {...{
          label: 'Informar boca vinculada ao silo durante a coleta?',
          value: moutBoundSilo,
          setValue: setMoutBoundSilo,
        }}
      />
      {/* <div
        className="panel-configuration-title"
        style={{
          marginTop: 16,
        }}
      >
        <h1>Unidade de trabalho</h1>
      </div> */}
      {/* <PanelConfigurationSelectUnity
        {...{
          label: 'Selecionar uma nova unidade',
          unities,
          unity,
          setUnity,
        }}
      /> */}
    </ListPanelConfigurationContainer>
  );
};

export { ListPanelConfiguration };
