import { usePageContext } from '../../../../context/usePage';
import { useSideMenuContext } from '../../../../context/useSideMenu';
import { Option } from './Option';
import {
  singleOptions,
  pqflOptions,
  collectOptions,
  managementOptions,
  tankerOptions,
} from './options';
import { ContainerMenuOptions } from './styles';

const MenuOptions = () => {
  const { selectedPage, setSelectedPage } = usePageContext();
  // const [listedOptions, setListenedOption] =
  //   useState<IOptionMenu[]>(singleOptions);
  const { sideMenuIsOpen } = useSideMenuContext();
  // useEffect(() => {
  //   if (searchedOption) {
  //     const filter = options.filter(item => {
  //       if (
  //         item
  //           .option!.normalize('NFD')
  //           .replace(/[\u0300-\u036f]/g, '')
  //           .toLocaleLowerCase()
  //           .includes(
  //             searchedOption
  //               .normalize('NFD')
  //               .replace(/[\u0300-\u036f]/g, '')
  //               .toLocaleLowerCase(),
  //           ) === true
  //       ) {
  //         if (
  //           item
  //             .option!.normalize('NFD')
  //             .replace(/[\u0300-\u036f]/g, '')
  //             .toLocaleLowerCase()
  //             .indexOf(
  //               searchedOption
  //                 .normalize('NFD')
  //                 .replace(/[\u0300-\u036f]/g, '')
  //                 .toLocaleLowerCase(),
  //             ) > -1
  //         ) {
  //           return true;
  //         }
  //         return false;
  //       }
  //       return false;
  //     });

  //     setListenedOption(filter);
  //   } else {
  //     setListenedOption(options);
  //   }
  // }, [searchedOption]);

  return (
    <ContainerMenuOptions sideMenuIsClose={sideMenuIsOpen}>
      {singleOptions.map((item, index) => {
        const { option, title, toPath } = item;
        return (
          <Option
            key={index}
            action={() => setSelectedPage({ option })}
            option={option}
            title={title}
            toPath={toPath}
            selected={selectedPage}
          />
        );
      })}

      <div className="option-separator">
        <h3>PQFL</h3>
      </div>

      {pqflOptions.map((item, index) => {
        const { option, title, toPath } = item;
        return (
          <Option
            key={index}
            action={() => setSelectedPage({ option })}
            option={option}
            title={title}
            toPath={toPath}
            selected={selectedPage}
          />
        );
      })}

      <div className="option-separator">
        <h3>Coleta</h3>
      </div>

      {collectOptions.map((item, index) => {
        const { option, title, toPath } = item;
        return (
          <Option
            key={index}
            action={() => setSelectedPage({ option })}
            option={option}
            title={title}
            toPath={toPath}
            selected={selectedPage}
          />
        );
      })}

      <div className="option-separator">
        <h3>Tanqueiro</h3>
      </div>

      {tankerOptions.map((item, index) => {
        const { option, title, toPath } = item;
        return (
          <Option
            key={index}
            action={() => setSelectedPage({ option })}
            option={option}
            title={title}
            toPath={toPath}
            selected={selectedPage}
          />
        );
      })}

      <div className="option-separator">
        <h3>Gerenciamento</h3>
      </div>

      {managementOptions.map((item, index) => {
        const { option, title, toPath } = item;
        return (
          <Option
            key={index}
            action={() => setSelectedPage({ option })}
            option={option}
            title={title}
            toPath={toPath}
            selected={selectedPage}
          />
        );
      })}
    </ContainerMenuOptions>
  );
};

export { MenuOptions };
