import { error } from 'console';
import { useEffect, useState } from 'react';
import Load from 'react-loading';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import themes from '../../../../../themes';
import { META_COLLECT_API } from '../../../api';
import { DontExistsItem } from '../../../components/DontExistsItem';
import { DontExistsItemFlex } from '../../../components/DontExistsItem/styles';
import { ListDischargeSilo } from '../../../components/List/ListDischargeSilo';
import { ListTankMouths } from '../../../components/List/ListTankMouths';
import { LoadingData } from '../../../components/LoadingData';
import { CreateNewSiloInCollectDischarge } from '../../../components/Modal/CreateNewSiloInCollectDischarge';
import { PageHeader } from '../../../components/PageHeader';
import { useSigninContext } from '../../../context/AuthProviderPages/Signin';
import { useDischargeContext } from '../../../context/ProviderPage/Descarga';
import { usePageContext } from '../../../context/usePage';
import { ContainerPage, PageContent } from '../../../styles/global.styles';
import { DischargeSilo } from '../../../types/DischargeSilo';
import {
  IMouth,
  IMouthsWithStorageValueApp,
  MouthTank,
} from '../../../types/MouthTank';
import { Silo } from '../../../types/Silo';
import { formatInputNumberPositiveAndNegative } from '../../../utils/formatInputNumberPositiveAndNegative';
import { executeCountTotalPlataform, handleDeschargeCollet } from './services';
import { DeschargeContent, NewSiloButton } from './styles';

interface IGetMouthTank {
  tanqueveiculo: MouthTank[];
}

const DescargaSelecionada = () => {
  const { setSelectedPage } = usePageContext();
  const { DFIDCOLETA } = useParams();
  const [silos, setSilos] = useState<Silo[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [openModal, setOpenModal] = useState<boolean>();
  const [createdDischargeCollectSilo, setCreatedDischargeCollectSilo] =
    useState<Silo>({});
  const [dischargeSilo, setDischargeSilo] = useState<DischargeSilo[]>([]);
  const [volume, setVolume] = useState<number | null>(null);
  const [date, setDate] = useState<string>('');
  const [hour, setHour] = useState<string>('');
  const [tankMouths, setTankMouths] = useState<MouthTank[]>([]);
  const [startDate, setStartDate] = useState('');
  const [startHour, setStartHour] = useState('');
  const [endDate, setEndDate] = useState('');
  const [endHour, setEndHour] = useState('');
  const [LATITUDE, SETLATITUDE] = useState<number>();
  const [LONGITUDE, SETLONGITUDE] = useState<number>();
  const [loadingDischargeCollect, setLoadingDischargeCollect] =
    useState<boolean>(false);
  const [vehicleLeak, setVehicleLeak] = useState<string>('');
  const [externalCleaning, setExternalCleaning] = useState<string>('');
  const [wagonerCleaning, setWagonerCleaning] = useState<string>('');
  const [observation, setObservation] = useState<string>('');
  const [acidityTemperature, setAcidityTemperature] = useState<string>('');
  const [acidityStart, setAcidityStart] = useState<string>('');
  const [acidityEnd, setAcidityEnd] = useState<string>('');
  const [alkalineTemperature, setAlkalineTemperature] = useState<string>('');
  const [alkalineStart, setAlkalineStart] = useState<string>('');
  const [alkalineEnd, setAlkalineEnd] = useState<string>('');
  const [vehicleArrival, setVehicleArrival] = useState<string>('');
  const [acidityStartHour, setAcidityStartHour] = useState<string>('');
  const [acidityEndHour, setAcidityEndHour] = useState<string>('');
  const [alkalineStartHour, setAlkalineStartHour] = useState<string>('');
  const [alkalineEndHour, setAlkalineEndHour] = useState<string>('');
  const [totalPlataform, setTotalPlataform] = useState<string>('');
  const [siloMouths, setSiloMouths] = useState<IMouth[]>([]);
  const [editing, setEditing] = useState<boolean>(false);
  const [mouthsWithStorageValueApp, setMouthsWithStorageValueApp] = useState<
    IMouthsWithStorageValueApp[]
  >([]);

  const { selectedDischargeCollect } = useDischargeContext();
  const { path, user } = useSigninContext();
  const { DFCONFIGURACAOMOTORISTA, DFCONFIGURACAOCIP } = user!;

  const handleCloseAndOpenModal = () => {
    setEditing(false);
    setOpenModal(!openModal);
  };

  useEffect(() => {
    setSelectedPage({ option: 'descarga' });
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        position => {
          const { latitude, longitude } = position.coords;
          SETLATITUDE(latitude);
          SETLONGITUDE(longitude);
        },
        () => {
          return toast.error(
            'Atenção, você precisa permitir o GPS para que a descarga possa ser registrada!',
          );
        },
        { enableHighAccuracy: true, timeout: 5000, maximumAge: 0 },
      );
    }
    META_COLLECT_API.get<IGetMouthTank>(
      `/tanqueveiculo/bocas?DFIDCOLETA=${DFIDCOLETA}`,
      {
        headers: {
          path,
        },
      },
    )
      .then(({ data }) => {
        const tanques = [];
        for (const va in data.tanqueveiculo) {
          const item: MouthTank = {
            ...data.tanqueveiculo[va],
            DFTEMPERATURA: '',
            DFLACRE: '',
            DFIDCOLETA: DFIDCOLETA!,
          };
          tanques.push(item);
        }
        setTankMouths(tanques);
        META_COLLECT_API.get(`/silo?hasPagination=false`, {
          headers: {
            path,
          },
        }).then(({ data }) => {
          META_COLLECT_API.get(
            `/bocaarmazenada/plataforma?DFIDCOLETA=${DFIDCOLETA}`,
            {
              headers: {
                path,
              },
            },
          )
            .then(({ data }) => {
              setMouthsWithStorageValueApp(data.mouths);
              setLoading(false);
            })
            .catch(error => {
              alert(error);
            });
          setSilos(data.unidades);
        });
      })
      .catch(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    executeCountTotalPlataform({ dischargeSilo, setTotalPlataform });
  }, [dischargeSilo]);

  const handleCreateNewDischargeSilo = (item: DischargeSilo) => {
    const { silo, volume, date, hour, selectedSiloMouths } = item;
    if (
      silo.DFIDSILO === undefined ||
      volume === null ||
      hour === '' ||
      date === ''
    ) {
      return toast.error('Você precisa informar todos os campos!');
    }
    const filterDischargeSilo = dischargeSilo.filter(
      item => item.silo.DFIDSILO === silo.DFIDSILO,
    );
    const formatSiloMouths = siloMouths.map(siloMouth => {
      return {
        ...siloMouth,
        DFIDSILO: item.silo.DFIDSILO,
      };
    });
    if (filterDischargeSilo.length > 0) {
      return toast.error('Já existe um silo com volume informado!');
    }
    setDischargeSilo([
      ...dischargeSilo,
      { ...item, selectedSiloMouths: formatSiloMouths },
    ]);
    setVolume(null);
    setDate('');
    setHour('');
    setCreatedDischargeCollectSilo({});
    setSiloMouths([]);
    return handleCloseAndOpenModal();
  };

  const handleRemoveDischargeSilo = (selectedSilo: Silo) => {
    setDischargeSilo(
      dischargeSilo.filter(
        item => item.silo.DFIDSILO !== selectedSilo.DFIDSILO,
      ),
    );
  };

  const handleEditDischargeSilo = (selectedSilo: DischargeSilo) => {
    const { silo, volume, date, hour, selectedSiloMouths } = selectedSilo;
    if (
      silo.DFIDSILO === undefined ||
      volume === null ||
      hour === '' ||
      date === ''
    ) {
      return toast.error('Você precisa informar todos os campos!');
    }
    const filterDischargeSilo = dischargeSilo.filter(
      discharge => discharge.silo.DFIDSILO !== selectedSilo.silo.DFIDSILO,
    );
    if (filterDischargeSilo.some(discharge => discharge.volume === volume)) {
      return toast.error('Já existe um silo com volume informado!');
    }
    const formatSiloMouths = siloMouths.map(siloMouth => ({
      ...siloMouth,
      DFIDSILO: selectedSilo.silo.DFIDSILO,
    }));
    const updatedDischargeSilo = dischargeSilo.map(discharge =>
      discharge.silo.DFIDSILO === selectedSilo.silo.DFIDSILO
        ? { ...selectedSilo, selectedSiloMouths: formatSiloMouths }
        : discharge,
    );
    setDischargeSilo(updatedDischargeSilo);
    setVolume(null);
    setDate('');
    setHour('');
    setCreatedDischargeCollectSilo({});
    setSiloMouths([]);
    return handleCloseAndOpenModal();
  };

  const handleOpenEditDischargeSilo = (selectedSilo: DischargeSilo) => {
    setEditing(true);
    const { date, hour, selectedSiloMouths, silo, volume } = selectedSilo;
    setOpenModal(true);
    setCreatedDischargeCollectSilo(silo);
    setSiloMouths(selectedSiloMouths);
    setVolume(volume);
    setDate(date);
    setHour(hour);
  };

  const handleUpdateTankMouth = (id: number, field: string, value: string) => {
    const FVALUE = formatInputNumberPositiveAndNegative(value);
    if (field === 'DFTEMPERATURA') {
      const values = tankMouths.map(item =>
        item.DFBOCA === id
          ? {
              ...item,
              DFTEMPERATURA: FVALUE,
            }
          : item,
      );
      setTankMouths(values);
    }
    if (field === 'DFLACRE') {
      const values = tankMouths.map(item =>
        item.DFBOCA === id
          ? {
              ...item,
              DFLACRE: value,
            }
          : item,
      );
      setTankMouths(values);
    }
  };

  const discharge = {
    path,
    endDate,
    endHour,
    startDate,
    startHour,
    setLoadingDischargeCollect,
    dischargeSilo,
    tankMouths,
    DFIDCOLETA: Number(DFIDCOLETA),
    LATITUDE: Number(LATITUDE),
    LONGITUDE: Number(LONGITUDE),
    vehicleLeak,
    externalCleaning,
    wagonerCleaning,
    observation,
    acidityTemperature,
    acidityStart,
    acidityEnd,
    alkalineTemperature,
    alkalineStart,
    alkalineEnd,
    DFIDUSUARIO: Number(user?.DFIDUSUARIO),
    totalPlataform,
    alkalineEndHour,
    alkalineStartHour,
    acidityEndHour,
    acidityStartHour,
    vehicleArrival,
    user: user!,
    siloMouths,
    selectedDischargeCollect,
  };

  return (
    <>
      <PageHeader
        pageIcon={{ option: 'descarga' }}
        pageTitle={`Plataforma #${DFIDCOLETA}`}
        hasFilter={false}
      />
      <ContainerPage>
        <PageContent
          style={{
            justifyContent: `${loading === true ? 'center' : 'normal'}`,
            overflow: 'auto',
          }}
        >
          {loading ? (
            <LoadingData title="Carregados dados" />
          ) : (
            <>
              {silos.length === 0 || tankMouths.length === 0 ? (
                <DontExistsItemFlex>
                  <DontExistsItem title="Não existe uma coleta para descarga" />
                </DontExistsItemFlex>
              ) : (
                <DeschargeContent>
                  {DFCONFIGURACAOMOTORISTA === 'S' && (
                    <>
                      <div className="column-input">
                        <h2>Motorista</h2>
                        <div className="row-input">
                          <div className="column-input">
                            <label>
                              <b>Hora chegada</b>
                            </label>
                            <input
                              value={vehicleArrival}
                              onChange={e => setVehicleArrival(e.target.value)}
                              type="time"
                            />
                          </div>
                          <div className="column-input">
                            <label>
                              <b>Vazamento no veículo</b>
                            </label>
                            <select
                              value={vehicleLeak}
                              onChange={e => setVehicleLeak(e.target.value)}
                            >
                              <option value=""></option>
                              <option value="S">Sim</option>
                              <option value="N">Não</option>
                            </select>
                          </div>
                          <div className="column-input">
                            <label>
                              <b>Limpeza externa do veículo</b>
                            </label>
                            <select
                              value={externalCleaning}
                              onChange={e =>
                                setExternalCleaning(e.target.value)
                              }
                            >
                              <option value=""></option>
                              <option value="S">Sim</option>
                              <option value="N">Não</option>
                            </select>
                          </div>
                        </div>
                        <div className="row-input">
                          <div className="column-input">
                            <label>
                              <b>Higiene do motorista</b>
                            </label>
                            <select
                              value={wagonerCleaning}
                              onChange={e => setWagonerCleaning(e.target.value)}
                            >
                              <option value=""></option>
                              <option value="S">Sim</option>
                              <option value="N">Não</option>
                            </select>
                          </div>
                          <div className="column-input">
                            <label>
                              <b>Observação</b>
                            </label>
                            <input
                              style={{ width: 455 }}
                              placeholder="Observação"
                              value={observation}
                              onChange={e => setObservation(e.target.value)}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="line" />
                    </>
                  )}

                  {DFCONFIGURACAOCIP === 'S' && (
                    <>
                      <h2>CIP</h2>
                      <div className="row-input">
                        <div className="column-input">
                          <label>
                            <b>Temperatura Acidez</b>
                          </label>
                          <input
                            value={acidityTemperature}
                            placeholder="Temeperatura(C°)"
                            onChange={e =>
                              setAcidityTemperature(e.target.value)
                            }
                          />
                        </div>
                        <div className="column-input">
                          <label>
                            <b>Data início</b>
                          </label>
                          <input
                            value={acidityStart}
                            onChange={e => setAcidityStart(e.target.value)}
                            type="date"
                          />
                        </div>
                        <div className="column-input">
                          <label>
                            <b>Hora início</b>
                          </label>
                          <input
                            value={acidityStartHour}
                            onChange={e => setAcidityStartHour(e.target.value)}
                            type="time"
                          />
                        </div>
                        <div className="column-input">
                          <label>
                            <b>Data Fim</b>
                          </label>
                          <input
                            value={acidityEnd}
                            onChange={e => setAcidityEnd(e.target.value)}
                            type="date"
                          />
                        </div>
                        <div className="column-input">
                          <label>
                            <b>Hora fim</b>
                          </label>
                          <input
                            value={acidityEndHour}
                            onChange={e => setAcidityEndHour(e.target.value)}
                            type="time"
                          />
                        </div>
                      </div>
                      <div className="row-input">
                        <div className="column-input">
                          <label>
                            <b>Temperatura Alcalina</b>
                          </label>
                          <input
                            value={alkalineTemperature}
                            placeholder="Temeperatura(C°)"
                            onChange={e =>
                              setAlkalineTemperature(e.target.value)
                            }
                          />
                        </div>
                        <div className="column-input">
                          <label>
                            <b>Data início</b>
                          </label>
                          <input
                            value={alkalineStart}
                            onChange={e => setAlkalineStart(e.target.value)}
                            type="date"
                          />
                        </div>
                        <div className="column-input">
                          <label>
                            <b>Hora início</b>
                          </label>
                          <input
                            value={alkalineStartHour}
                            onChange={e => setAlkalineStartHour(e.target.value)}
                            type="time"
                          />
                        </div>
                        <div className="column-input">
                          <label>
                            <b>Data fim</b>
                          </label>
                          <input
                            value={alkalineEnd}
                            onChange={e => setAlkalineEnd(e.target.value)}
                            type="date"
                          />
                        </div>
                        <div className="column-input">
                          <label>
                            <b>Hora fim</b>
                          </label>
                          <input
                            value={alkalineEndHour}
                            onChange={e => setAlkalineEndHour(e.target.value)}
                            type="time"
                          />
                        </div>
                      </div>
                      <div className="line" />
                    </>
                  )}

                  <div className="column-input">
                    <h2>Descarga</h2>
                    <div className="row-input">
                      <div className="column-input">
                        <label>
                          <b>Data início</b>
                        </label>
                        <input
                          value={startDate}
                          onChange={e => setStartDate(e.target.value)}
                          type="date"
                        />
                      </div>
                      <div className="column-input">
                        <label>
                          <b>Hora início</b>
                        </label>
                        <input
                          value={startHour}
                          onChange={e => setStartHour(e.target.value)}
                          type="time"
                        />
                      </div>
                      <div className="column-input">
                        <label>
                          <b>Data fim</b>
                        </label>
                        <input
                          value={endDate}
                          onChange={e => setEndDate(e.target.value)}
                          type="date"
                        />
                      </div>
                      <div className="column-input">
                        <label>
                          <b>Hora fim</b>
                        </label>
                        <input
                          value={endHour}
                          onChange={e => setEndHour(e.target.value)}
                          type="time"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="line" />

                  <ListTankMouths
                    {...{ data: tankMouths, handleUpdateTankMouth }}
                  />
                  <div className="line" />

                  <div
                    {...{
                      className: 'column-input',
                      style: { alignSelf: 'flex-end', margin: 0 },
                    }}
                  >
                    <label>
                      <b>Total plataforma</b>
                    </label>
                    <input
                      readOnly
                      value={totalPlataform}
                      placeholder="0"
                      onChange={e => setTotalPlataform(e.target.value)}
                    />
                  </div>
                  <div className="line" />
                  <ListDischargeSilo
                    {...{
                      data: dischargeSilo,
                      handleRemoveDischargeSilo,
                      handleOpenEditDischargeSilo,
                    }}
                  />

                  <div className="row-buttons">
                    <NewSiloButton
                      color={themes.COLORS.GREEN_30}
                      onClick={handleCloseAndOpenModal}
                    >
                      Novo silo
                    </NewSiloButton>
                    <NewSiloButton
                      color={themes.COLORS.GREEN_100}
                      onClick={
                        loadingDischargeCollect
                          ? () => null
                          : () => handleDeschargeCollet({ discharge })
                      }
                    >
                      {loadingDischargeCollect ? (
                        <Load
                          height={25}
                          width={25}
                          color="white"
                          type="spin"
                        />
                      ) : (
                        'Descarregar'
                      )}
                    </NewSiloButton>
                  </div>
                </DeschargeContent>
              )}
            </>
          )}
        </PageContent>
      </ContainerPage>
      <CreateNewSiloInCollectDischarge
        {...{
          closeModal: handleCloseAndOpenModal,
          isOpen: openModal,
          silos,
          createdDischargeCollectSilo,
          setCreatedDischargeCollectSilo,
          handleCreateNewDischargeSilo,
          volume,
          setVolume,
          date,
          hour,
          setDate,
          setHour,
          DFIDCOLETA: DFIDCOLETA!,
          tankMouths,
          siloMouths,
          setSiloMouths,
          editing,
          handleEditDischargeSilo,
          mouthsWithStorageValueApp,
          dischargeSilo,
        }}
      />
    </>
  );
};

export { DescargaSelecionada };
