import { Buffer } from 'buffer';
import { DateTime } from 'luxon';
import { Fragment } from 'react/jsx-runtime';

import { ContainerListPage } from '../../../../../../components/List/styles';
import { ModalInfoTemplete } from '../../../../../../components/Modal/ModalInfoTemplete';
import {
  ContainerItemModal,
  RowItens,
} from '../../../../../../components/Modal/ModalInfoTemplete/styles';
import { PageHeader } from '../../../../../../components/PageHeader';
import { useSigninContext } from '../../../../../../context/AuthProviderPages/Signin';
import {
  ContainerPage,
  PageContent,
} from '../../../../../../styles/global.styles';
import { PQFLAnswerItem, PQFLVisitRow } from '../../page';
import PDF from '../pdf';
import { formatISODate } from '../table';

type ContentWrapperProps = {
  loading: boolean;
  row: PQFLVisitRow | null;
  openModal: boolean;
  handleCloseModal: () => void;
  children: React.ReactNode;
};

type ModalProps = {
  row: PQFLVisitRow;
  openModal: boolean;
  handleCloseModal: () => void;
};

const getAnswerValue = (item: PQFLAnswerItem) => {
  if (item.tipo_pergunta === 1) {
    return item.valor === 'S' ? 'Sim' : 'Não';
  }

  if (item.tipo_pergunta === 3) {
    return DateTime.fromISO(item.valor).toFormat('dd/MM/yyyy');
  }

  if (item.tipo_pergunta === 4) {
    return DateTime.fromISO(item.valor).toFormat('HH:mm');
  }

  return item.valor;
};

const Modal = ({ row, openModal, handleCloseModal }: ModalProps) => {
  return (
    <ModalInfoTemplete
      option={'pqfl_visitas'}
      titleModal={'Visita PQFL'}
      closeModal={handleCloseModal}
      isOpen={openModal}
    >
      <RowItens
        style={{
          marginBottom: '10px',
        }}
      >
        <ContainerItemModal>
          <label>Código</label>
          <div>
            <span>{row.id}</span>
          </div>
        </ContainerItemModal>
        <ContainerItemModal>
          <label>Responsável</label>
          <div>
            <span>{row.funcionario.nome}</span>
          </div>
        </ContainerItemModal>
      </RowItens>
      <RowItens
        style={{
          marginBottom: '10px',
        }}
      >
        <ContainerItemModal>
          <label>Propriedade</label>
          <div>
            <span>{row.propriedade.nome_propriedade}</span>
          </div>
        </ContainerItemModal>
        <ContainerItemModal>
          <label>Produtor</label>
          <div>
            <span>{row.propriedade.nome_produtor}</span>
          </div>
        </ContainerItemModal>
      </RowItens>
      <RowItens
        style={{
          marginBottom: '10px',
        }}
      >
        <ContainerItemModal>
          <label>Início</label>
          <div>
            <span>{formatISODate(row.data_inicio)}</span>
          </div>
        </ContainerItemModal>
        <ContainerItemModal>
          <label>Fim</label>
          <div>
            <span>{formatISODate(row.data_final)}</span>
          </div>
        </ContainerItemModal>
      </RowItens>
      <RowItens
        style={{
          marginBottom: '10px',
        }}
      >
        <ContainerItemModal>
          <label>Status</label>
          <div>
            <span>{row.status}</span>
          </div>
        </ContainerItemModal>
      </RowItens>
      <RowItens
        style={{
          marginBottom: '30px',
        }}
      >
        <ContainerItemModal>
          <label>Recomendação Técnica</label>
          <div>
            <span>
              {row.recomendacao_tecnica ? row.recomendacao_tecnica : '-'}
            </span>
          </div>
        </ContainerItemModal>
      </RowItens>
      {row.respostas
        .filter(resposta => resposta.status !== 'C')
        .map((resposta, index) => (
          <Fragment key={index}>
            <RowItens
              style={{
                marginBottom: '10px',
              }}
            >
              <h2>{resposta.questionario.descricao}</h2>
            </RowItens>
            <RowItens
              style={{
                marginBottom: '10px',
              }}
            >
              <ContainerListPage
                style={{
                  width: '100%',
                  overflow: 'auto',
                }}
              >
                <table>
                  <thead>
                    <tr>
                      <th className="first-table-th">Pergunta</th>
                      <th className="list-th">Respostas</th>
                    </tr>
                  </thead>
                  <tbody>
                    {resposta.itens
                      .sort((a, b) => a.ordem_pergunta - b.ordem_pergunta)
                      .map((item, index) => (
                        <tr key={index}>
                          <td className="list-td-small">
                            {item.grau1_pergunta}.{item.grau2_pergunta}{' '}
                            {item.texto_pergunta}
                          </td>
                          <td className="list-td-large">
                            {getAnswerValue(item)}
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </ContainerListPage>
            </RowItens>
          </Fragment>
        ))}
    </ModalInfoTemplete>
  );
};

export const ContentWrapper = ({
  loading,
  row: question,
  openModal,
  handleCloseModal,
  children,
}: ContentWrapperProps) => {
  const { user } = useSigninContext();
  const base64Data = user?.DFLOGOEMPRESA
    ? Buffer.from(user?.DFLOGOEMPRESA!.data).toString()
    : undefined;

  return (
    <>
      <PageHeader
        pageIcon={{ option: 'pqfl_visitas' }}
        pageTitle="PQFL / Visitas"
      />
      <ContainerPage>
        <PageContent
          style={{
            justifyContent: loading === true ? 'center' : 'space-between',
          }}
        >
          {children}
        </PageContent>
        {question && (
          <>
            <Modal
              row={question}
              openModal={openModal}
              handleCloseModal={handleCloseModal}
            />
            <div style={{ display: 'none' }}>
              <PDF
                base64Data={base64Data}
                username={user?.DFLOGINUSUARIO}
                company={{
                  legalName: question.unidade.nome,
                }}
                answers={question.respostas.filter(
                  resposta => resposta.status !== 'C',
                )}
                property={{
                  name: question.propriedade.nome_propriedade,
                  municipalityName: question.propriedade.nome_municipio,
                }}
                technicalRecommendation={question.recomendacao_tecnica}
                key={question.id}
                endDate={question.data_final}
              />
            </div>
          </>
        )}
      </ContainerPage>
    </>
  );
};
