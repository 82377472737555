/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable consistent-return */
import { Buffer } from 'buffer';
import React, {
  ReactNode,
  createContext,
  useContext,
  useState,
  Dispatch,
  SetStateAction,
  useEffect,
} from 'react';
import { toast } from 'react-toastify';

import { META_COLLECT_API } from '../../../api';
import { Unity } from '../../../types/Unity';
import { User } from '../../../types/User';

interface IContextProps {
  children: ReactNode;
}

interface IHandleUpdateConfigurations {
  setLoading: Dispatch<SetStateAction<boolean>>;
  cip: boolean;
  motorist: boolean;
  unity: Unity;
  moutBoundSilo: boolean;
}
interface IHandleLoginUser {
  event: React.FormEvent<HTMLFormElement>;
  path?: string;
  DFLOGINUSUARIO?: string;
  DFSENHAUSUARIO?: string;
  handleNavigateToCollect: () => void;
}

interface IContext {
  handleLoginUser: ({
    event,
    DFLOGINUSUARIO,
    DFSENHAUSUARIO,
    path,
    handleNavigateToCollect,
  }: IHandleLoginUser) => void;
  loadDataBase: (path: string) => void;
  handleSignout: () => void;
  loadingData: boolean;
  databaseLoadStatus?: 'error' | 'success';
  user?: User | null;
  setUser: Dispatch<SetStateAction<User | null | undefined>>;
  setPath: Dispatch<SetStateAction<string>>;
  path: string;
  handleUpdateConfigurations: ({
    setLoading,
    cip,
    motorist,
    unity,
  }: IHandleUpdateConfigurations) => void;
  unity: Unity;
  setUnity: Dispatch<SetStateAction<Unity>>;
  unityOptions: Unity[];
  handleUpdateUnity: (current: Unity) => void;
  loadingLogin: boolean;
  bufferImage: string;
}

const Context = createContext({} as IContext);

const SigninContextProvider = ({ children }: IContextProps) => {
  const [loadingData, setLoadingData] = useState<boolean>(false);
  const [path, setPath] = useState<string>('');
  const [unity, setUnity] = useState<Unity>({});
  const [unityOptions, setUnityOptions] = useState<Unity[]>([]);
  const [databaseLoadStatus, setDatabaseLoadStatus] = useState<
    'error' | 'success'
  >();
  const [loadingLogin, setLoadingLogin] = useState(false);
  const [user, setUser] = useState<User | null>();
  const [bufferImage, setBufferImage] = useState<string>('');

  useEffect(() => {
    if (user?.DFLOGOEMPRESA) {
      setBufferImage(
        `data:image/png;base64,${
          user.DFLOGOEMPRESA && Buffer.from(user.DFLOGOEMPRESA.data).toString()
        }`,
      );
    }
  }, [user]);

  const handleLoginUser = async ({
    event,
    path,
    DFLOGINUSUARIO,
    DFSENHAUSUARIO,
    handleNavigateToCollect,
  }: IHandleLoginUser) => {
    event.preventDefault();
    if (!DFLOGINUSUARIO || !DFSENHAUSUARIO || !unity.DFIDUNIDADE) {
      return toast.error('Os campos precisam ser preenchidos');
    }
    setLoadingLogin(true);
    try {
      await META_COLLECT_API.get(
        `/usuario/login?DFLOGINUSUARIO=${DFLOGINUSUARIO}&DFSENHAUSUARIO=${DFSENHAUSUARIO}`,
        {
          headers: {
            path,
          },
        },
      ).then(({ data }) => {
        if (data) {
          const { user, configuration } = data;
          const dataUser = { ...user, ...configuration, ...unity };
          setUser(dataUser);
          sessionStorage.setItem(
            'meta_collect_user',
            JSON.stringify({
              ...dataUser,
              path,
            }),
          );
          setLoadingLogin(false);
          handleNavigateToCollect();
          return toast.success('Usuário foi logado com sucesso!');
        }
      });
    } catch (error: any) {
      setLoadingLogin(false);
      return toast.error(error.response.data.error, {
        className: 'class-error',
      });
    }
  };

  const loadDataBase = async (path: string) => {
    setLoadingData(true);
    await META_COLLECT_API.post('/cliente/authenticate-database', {
      path,
    })
      .then(() => {
        META_COLLECT_API.get(`/unidade`, {
          headers: {
            path,
          },
        }).then(({ data }) => {
          console.log(data);

          setUnityOptions(data.unidades);
          setLoadingData(false);
          setDatabaseLoadStatus('success');
        });
      })
      .catch(errr => {
        console.log(errr);
        setLoadingData(false);
        setDatabaseLoadStatus('error');
      });
  };

  const handleSignout = async () => {
    setUser(null);
    sessionStorage.removeItem('meta_collect_user');
  };

  const handleUpdateConfigurations = ({
    setLoading,
    cip,
    motorist,
    unity,
    moutBoundSilo,
  }: IHandleUpdateConfigurations) => {
    setLoading(true);
    META_COLLECT_API.post(
      '/configuracao-painel/upsert',
      {
        DFCONFIGURACAOMOTORISTA: motorist ? 'S' : 'N',
        DFCONFIGURACAOCIP: cip ? 'S' : 'N',
        DFIDCONFIGURACAOPAINEL: user?.DFIDCONFIGURACAOPAINEL,
        DFCONFIGURACAOSILOBOCA: moutBoundSilo ? 'S' : 'N',
      },
      {
        headers: {
          path,
        },
      },
    )
      .then(({ data }) => {
        const { DFIDCONFIGURACAOPAINEL, message } = data;
        const updateUser = {
          ...user!,
          ...unity!,
          DFIDCONFIGURACAOPAINEL,
          DFCONFIGURACAOMOTORISTA: motorist ? 'S' : 'N',
          DFCONFIGURACAOCIP: cip ? 'S' : 'N',
          DFCONFIGURACAOSILOBOCA: moutBoundSilo ? 'S' : 'N',
        };

        sessionStorage.setItem(
          'meta_collect_user',
          JSON.stringify({
            ...updateUser,
            path,
          }),
        );
        setUser(updateUser);
        toast.success(message);
        setLoading(false);
      })
      .catch(error => {
        toast.error(error.response.data.error);
        setLoading(false);
      });
  };

  const handleUpdateUnity = (current: Unity) => {
    if (current.DFIDUNIDADE === user?.DFIDUNIDADE) {
      return toast.error(
        'Você já esta com esta unidade selecionada, por favor selecione uma nova!',
      );
    }
    const newUserData = {
      ...user!,
      ...current!,
      path,
    };
    sessionStorage.setItem('meta_collect_user', JSON.stringify(newUserData));
    setUser(newUserData);
    return toast.success('Unidade selecionada com sucesso!');
  };

  return (
    <Context.Provider
      value={{
        databaseLoadStatus,
        handleLoginUser,
        loadDataBase,
        loadingData,
        user,
        setUser,
        path,
        setPath,
        handleSignout,
        handleUpdateConfigurations,
        unity,
        setUnity,
        unityOptions,
        handleUpdateUnity,
        loadingLogin,
        bufferImage,
      }}
    >
      {children}
    </Context.Provider>
  );
};

function useSigninContext() {
  const context = useContext(Context);
  return context;
}

export { useSigninContext, SigninContextProvider };
