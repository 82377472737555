import ArrowLeft from '../../../../../assets/paginator/ArrowLeft.svg';
import ArrowRight from '../../../../../assets/paginator/ArrowRight.svg';
import { Container, PaginatorContainer } from './styles';

type PaginatorProps = {
  size: number;
  limit: number;
  setLimit: (limit: number) => void;
  page: number;
  setPage: (page: number) => void;
  hasMore: boolean;
  count: number;
};

export const Paginator = ({
  size,
  limit,
  page,
  setPage,
  hasMore,
  count,
}: PaginatorProps) => {
  return (
    <Container>
      <PaginatorContainer quantity={limit} page={page} maxPage={limit}>
        <img
          src={ArrowRight}
          style={{
            cursor: page > 1 ? 'pointer' : 'not-allowed',
            opacity: page > 1 ? 1 : 0.3,
          }}
          onClick={() => {
            if (page > 1) {
              setPage(page - 1);
            }
          }}
        />
        <span className="itens-quantity-page">
          {size === 0 ? 0 : page * limit - limit + 1} -{' '}
          {(size === 0 ? 0 : page * limit - limit + 1) + count - 1}
        </span>
        <img
          src={ArrowLeft}
          style={{
            cursor: hasMore ? 'pointer' : 'not-allowed',
            opacity: hasMore ? 1 : 0.3,
          }}
          onClick={() => {
            if (hasMore) {
              setPage(page + 1);
            }
          }}
        />
      </PaginatorContainer>
    </Container>
  );
};
